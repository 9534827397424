<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="wp-image-23272 aligncenter" src="@/assets/news/9/1.jpg" alt="图片" width="555" height="370"  sizes="(max-width: 555px) 100vw, 555px"></p>
        <p>爱尔兰经济复苏超预期</p>
        <p>爱尔兰在近日发布的《季度公报》中称，爱尔兰经济或将在今年年底恢复到疫情前的水平。</p>
        <p>爱尔兰央行也预测，今年的涡轮增压式增长将达到15.3%，几乎是7月份之前预测的两倍，明年将增长7.2%。人们在疫情期间积累的储蓄将对经济发展产生巨大影响，而经济的迅速复苏意味着将大大缓解疫情期间对于就业和生计的影响。同时，对人们再次恢复到正常消费起到重要作用。</p>
        <p>央行计算出，在过去18个月的220亿欧元储蓄中，大约有130-160亿欧元被认为是“超额储蓄”，其中一些可能会回流到经济中。预计工资也将上涨，今年的平均涨幅为5.1%，明年为4.8%。但并非平均分布在所有行业，而是集中在建筑和金融等领域。央行还预计复苏期间将创造16万个工作岗位，到2023 年失业率将降至5.9%。</p>
        <p>实际上，近几年爱尔兰在经济发展和人民生活质量的表现上都相当亮眼。</p>
        <p>根据国际货币基金组织公布数据显示，2020 年爱尔兰人均 GDP 为 83,850 美元，全球排名第三，高于美国（63,416 美元）、澳大利亚（52,825 美元）、德国（45,733 美元）、英国（40,406 美元）、法国（39,907 美元）等国的平均水平。而在联合国 2020 年公布的，根据预期寿命、受教育程度和人均收入进行的人类发展指数排名种，爱尔兰也位列全球第二，生活质量也深受好评。</p>
        <p>自新冠疫情蔓延以来，爱尔兰政府一直在积极应对，防御措施不断升级，便民举措层出不穷。</p>
        <p>为了让投资人能更灵活地准备和递交移民申请，解除只能在窗口期递交申请的限制，目前，爱尔兰移民局已全面取消窗口期！申请人可以随时递交申请，不再受窗口期限制！</p>
        <p><img loading="lazy" class="wp-image-23273 aligncenter" src="@/assets/news/9/2.jpg" alt="图片" width="718" height="343"  sizes="(max-width: 718px) 100vw, 718px"></p>
        <p>此前一直观望已久的投资人，可以借此机会，做足准备，搭上这趟顺风车。</p>
        <p><a href="/irelandiip"><strong>爱尔兰投资移民优势</strong></a></p>
        <p><span style="color: #0000ff;"><strong>优质教育：</strong></span></p>
        <p>•爱尔兰教育系统排名世界第七*</p>
        <p>•爱尔兰所有大学都排名世界前列</p>
        <p>•欧盟国家、美国和英国的知名企业青睐爱尔兰毕业生</p>
        <p>•子女免费就读公立小学和中学，大学的学费远低于英美</p>
        <p>•爱尔兰和英国互相承认中学学分</p>
        <p>*资料来源：2019年IMD世界竞争力排名</p>
        <p><span style="color: #0000ff;"><strong>就业优势：</strong></span></p>
        <p>•Google, Facebook, IBM, Accenture, 辉瑞等超过1150家跨国企业将欧洲总部设在爱尔兰</p>
        <p>•大量优质、高薪的就业机会，如，Google在爱尔兰雇员人数超7,000人，Apple超6,000人</p>
        <p>•2021年，爱尔兰全职员工的平均年收入为53817欧元</p>
        <p>•约78%的爱尔兰毕业生离校后九个月内就能找到工作</p>
        <p><span style="color: #0000ff;"><strong>投资与经商宝地：</strong></span></p>
        <p>•2014-2018连续五年经济增长率保持欧盟超高水平</p>
        <p>•2011-2017年度IBM全球区域趋势报告称爱尔兰为外资直接投资量超大国</p>
        <p>•2017年度IMD世界竞争力年鉴评价爱尔兰为优秀投资回报国</p>
        <p>•2013年福布斯评价爱尔兰为世界优质经商国</p>
        <p>•国家综合知识、国家繁荣度和平等水平排名世界前列</p>
        <p>•企业所得税税率低至12.5%</p>
        <p><span style="color: #0000ff;"><strong>移民政策宽松：</strong></span></p>
        <p>•先获批再投资</p>
        <p>•无语言要求</p>
        <p>•无管理经验要求</p>
        <p>•无移民监</p>
        <p>•6个月左右即可获批</p>
        <p><span style="color: #0000ff;"><strong>护照含金量高：</strong></span></p>
        <p>爱尔兰护照的价值相当于英国护照+欧盟护照。</p>
        <p>•英国护照：根据爱尔兰与英国签署的共同区域协定CTA，爱尔兰公民前往英国无需申请签证，且在英国无需申请永居，进英国后可自动享有自由定居、工作、学习、享受社会福利甚至选举的权利</p>
        <p>•欧盟护照：爱尔兰是欧盟国家，持爱尔兰护照可以在欧盟成员国读书、工作</p>
        <p>跃迁爱尔兰投资移民项目 –&nbsp;助您圆梦爱尔兰</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "9",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:9,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>